import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const applicationIds = {
  'localhost:7107': '6aecce96-b089-4f78-ad41-5478269b0be6',
  'trapext-dev.milwaukeetool.com': '6aecce96-b089-4f78-ad41-5478269b0be6',
  'trapext-test.milwaukeetool.com': '7ac273d0-ac80-4a9e-a388-5c715e13b7d1',
  'trapext-staging.milwaukeetool.com': 'aff80978-1264-4db7-9a8f-53b92b2dd904'
};
const MILWAUKEE_TOOL_TENANT_ID = '324f02a1-b901-46ec-867e-a62e49604dbb';

const entraAuthHosts = Object.keys(applicationIds);

// only apply entra auth if we're not in the embedded browser and the host is in the list
// meaning we're in azure TRAP instead of onprem TRAP
export const isUsingEntraAuth = !window.mkeChromeBrowser && entraAuthHosts.includes(window.location.host);

export const loginRequest = {
  scopes: ["openid", "profile", "offline_access"],
  forceRefresh: true,
};

export const msalConfig = {
  auth: {
    clientId: applicationIds[window.location.host],
    authority: `https://login.microsoftonline.com/${MILWAUKEE_TOOL_TENANT_ID}`,
    redirectUri: "/",
    postLogoutRedirectUri: "/"
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export function MsalAuthWrapper({ children }) {
  if (!isUsingEntraAuth) {
    return children;
  }

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        {children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  )
}