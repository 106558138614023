import React, { Fragment, useEffect, useState, useCallback } from 'react';
import {injectIntl} from 'react-intl';
import { connect } from 'react-redux';
import { StyledButtonRow } from './styled';
import { toastr } from 'react-redux-toastr';
import { StyledGrid } from '../../shared/styled';
import { useParsedQueryString } from '../../hooks';
import PageLoader from '../../components/PageLoader';
import { TranDetails, TestCardDialog } from './components';
import { transactionTypeEnum } from '../../shared/constants';
import TranCompleteModal from '../../components/TranComplete';
import { configurationActions, paymentMethodActions } from '../../store';
import { AddCreditCard, Button, Card, Col, CardBody, CardHeader, SelectCreditCard, useToggle } from '@met/react-components';
import { getExternalSystemId, getLocationId } from '../../helpers';

const PaymentMethod = ({ 
  creditCard, 
  appConfig,
  appConfigIsLoaded,
  isInitializing,
  isProcessing,
  addCard,
  addTestCard,
  deleteCard, 
  journalCharge, 
  setSelectedCard, 
  preAuthSalesOrder, 
  preAuthServiceOrder, 
  preAuthGenericOrder,
  getCardsForAccount,
  getAppConfig,
  intl,
  canAccountSaveCreditCards,
  canSaveCreditCard
}) => {
  const [success, setSuccess] = useState(false);
  const [refreshCards, setRefreshCards] = useState(true);
  const [testCardDialogHidden, toggleTestCardDialog] = useToggle(true);

  const { queryParams, isParsed } = useParsedQueryString();

  useEffect(() => {
    if(isParsed) {
      getAppConfig(getLocationId(queryParams));
    }
  }, [queryParams, isParsed]);

  useEffect(() => {
    if (isParsed && refreshCards) {
      getCardsForAccount(queryParams.accountNumber, getLocationId(queryParams))
        .catch((e) => toastr.error('Error: Get Cards', `Could not retrieve credit card data for account ${queryParams.accountNumber}.`))
        .then(() => setRefreshCards(false));
      canAccountSaveCreditCards(queryParams.accountNumber)
        .catch((e) => toastr.error('Error: Can Save Card', `Could not retrieve can save credit card for account ${queryParams.accountNumber}.`))
    }
  }, [isParsed, refreshCards, queryParams]);

  const handleAddCard = (cardData, nonce, cardholderName, saveCard) => {    
    return addCard(cardData, nonce, cardholderName, saveCard, queryParams.accountNumber, getLocationId(queryParams), getExternalSystemId(queryParams))
      .catch((e) => {
        e.text().then((text) => {
          let er =  text ? JSON.parse(text) : {};
          if (er.ErrorCode) {
            toastr.error('Error: Adding Card', intl.formatMessage({id: 'errorCodes.' + er.ErrorCode, 'defaultMessage': 'Credit card could not be added.'}));
          } else {
            toastr.error('Error: Adding Card', 'Credit card could not be added.');
          }
        }).catch(() => {
          toastr.error('Error: Adding Card', 'Credit card could not be added.');
        });
    });
  };
  
  const handleAddTestCard = useCallback((nonce, cardholderName) => {    
    if (nonce === 'cnon:card-nonce-ok') {
      return addCard({ 'billing_postal_code': '94103' }, nonce, cardholderName, true, queryParams.accountNumber, getLocationId(queryParams), getExternalSystemId(queryParams))
        .catch((e) => toastr.error('Error: Add Card', 'Credit card could not be added.'));
    }
    return addTestCard(nonce, cardholderName, true, queryParams.accountNumber, null, getExternalSystemId(queryParams))
      .catch((e) => toastr.error('Error: Add Card', 'Credit card could not be added.'));
  }, [queryParams]);
  
  const handleProcessPayment = () => {
    const cardId = creditCard.selectedCard ? creditCard.selectedCard.id : null;
    const { type, salesId, serviceOrderId, journalId, accountNumber, amount, currencyCode, externalSystem } = queryParams;
    const normalizedType = (type || '').toUpperCase();
    const externalSystemId = getExternalSystemId(queryParams);
    const locationId = getLocationId(queryParams);

    if (normalizedType === transactionTypeEnum.SALES_ID && externalSystem === 'hybris') {
      preAuthGenericOrder(salesId, accountNumber, amount, currencyCode, locationId, cardId, 1, "SALESORDER", externalSystemId) // sales order preauth hybris
      .then(() => setSuccess(true))
      .catch((e) => {
        e.text().then((text) => {
          const er =  text ? JSON.parse(text) : {};
          if (er.ErrorCode) {
            toastr.error('Error: Processing Payment', intl.formatMessage({id: 'errorCodes.' + er.ErrorCode, 'defaultMessage': 'Unable to preauthorize the sales order.'}));
          } else {
            toastr.error('Error: Processing Payment', 'Unable to preauthorize the sales order.');
          }
        }).catch(() => {
          toastr.error('Error: Processing Payment', 'Unable to preauthorize the sales order.');
        });
      });
    }
    else if (normalizedType === transactionTypeEnum.SALES_ID) {
      preAuthSalesOrder(salesId, accountNumber, 1, currencyCode, locationId, cardId)
        .then(() => setSuccess(true))
        .catch((e) => {
          e.text().then((text) => {
            const er =  text ? JSON.parse(text) : {};            
            if (er.ErrorCode) {
              toastr.error('Error: Processing Payment', intl.formatMessage({id: 'errorCodes.' + er.ErrorCode, 'defaultMessage': 'Unable to preauthorize the sales order.'}));
            } else {
              toastr.error('Error: Processing Payment', 'Unable to preauthorize the sales order.');  
            }            
          }).catch(() => {
            toastr.error('Error: Processing Payment', 'Unable to preauthorize the sales order.');  
          });
        });
    } else if (normalizedType === transactionTypeEnum.JOURNAL_ID) {
      journalCharge(journalId, accountNumber, amount, currencyCode, locationId, cardId, externalSystemId)
        .then(() => setSuccess(true))
        .catch((e) => {
            e.text().then((text) => {
              const er =  text ? JSON.parse(text) : {};
              if (er.ErrorCode) {
                toastr.error('Error: Processing Payment', intl.formatMessage({id: 'errorCodes.' + er.ErrorCode, 'defaultMessage': 'Unable to charge the card.'}));
              } else {
                toastr.error('Error: Processing Payment', 'Unable to charge the card.');
              }
            }).catch(() => {
              toastr.error('Error: Processing Payment', 'Unable to charge the card.');
            });
        });
    } else if ((normalizedType === transactionTypeEnum.SERVICE_PREAUTH || normalizedType === transactionTypeEnum.SERVICE_ORDER_ID) && externalSystem === 'oracle') {
      preAuthGenericOrder(serviceOrderId, accountNumber, amount, currencyCode, locationId, cardId, 1, "SERVICEORDER", externalSystemId) // service order per-auth oracle
        .then(() => setSuccess(true))
        .catch((e) => {
          e.text().then((text) => {
            const er =  text ? JSON.parse(text) : {};
            if (er.ErrorCode) {
              toastr.error('Error: Processing Payment', intl.formatMessage({id: 'errorCodes.' + er.ErrorCode, 'defaultMessage': 'Unable to preauthorize the service order.'}));
            } else {
              toastr.error('Error: Processing Payment', 'Unable to preauthorize the service order.');
            }
          }).catch(() => {
            toastr.error('Error: Processing Payment', 'Unable to preauthorize the service order.');
          });
        });
    } else if (normalizedType === transactionTypeEnum.SERVICE_PREAUTH || normalizedType === transactionTypeEnum.SERVICE_ORDER_ID) {
      preAuthServiceOrder(serviceOrderId, accountNumber, 1, currencyCode, locationId, cardId)
        .then(() => setSuccess(true))
        .catch((e) => {
          e.text().then((text) => {
            const er =  text ? JSON.parse(text) : {};
            if (er.ErrorCode) {
              toastr.error('Error: Processing Payment', intl.formatMessage({id: 'errorCodes.' + er.ErrorCode, 'defaultMessage': 'Unable to preauthorize the service order.'}));
            } else {
              toastr.error('Error: Processing Payment', 'Unable to preauthorize the service order.');
            }
          }).catch(() => {
            toastr.error('Error: Processing Payment', 'Unable to preauthorize the service order.');
          });
        });
    }
  };

  // External trap only
  const handleCompleteModalClose = () => {
    setSuccess(false);
  };

  return (
    <Fragment>
      <Col xs={12} md={8}>
        <Card >
          <CardHeader>Payment Method</CardHeader>
          <CardBody>
            <StyledGrid fluid>
              <PageLoader loading={isInitializing} />
              {
                (appConfigIsLoaded && !isInitializing && !refreshCards) && (
                  <Fragment> 
                    <SelectCreditCard 
                      creditCards={creditCard.cards}
                      onSetSelectedCard={setSelectedCard}
                      onDeleteCard={deleteCard}  
                      noPaymentMessage={canSaveCreditCard ? `Add payment methods with 'Add Credit Card' and save them by checking 'Save Card'.` : `Add payment methods with 'Add Credit Card'.`}
                    />
                    <br/>
                    <StyledButtonRow>
                      {
                        appConfig.squareApplicationId ? (
                          <AddCreditCard onCreditCardAdded={handleAddCard} applicationId={appConfig.squareApplicationId} canSave={canSaveCreditCard} />
                        ) : ""
                      }
                      {
                        appConfig.allowTestCards && (
                          <Button onClick={toggleTestCardDialog}>Add Test Card</Button>
                        )
                      }
                      <Button 
                        primary 
                        disabled={!creditCard.selectedCard} 
                        loading={isProcessing} 
                        onClick={handleProcessPayment}
                      >
                        Process Payment
                      </Button>
                    </StyledButtonRow>
                  </Fragment>
                )
              }
            </StyledGrid>
          </CardBody>
        </Card>
      </Col>
      <Col xs={12} md={4}>      
        <TranDetails queryParams={queryParams} />
      </Col>
      {
        appConfig.allowTestCards && (
          <TestCardDialog 
            hidden={testCardDialogHidden}
            toggle={toggleTestCardDialog}
            addTestCard={handleAddTestCard}
            isLoading={creditCard.addCard.isLoading}
          />
        )
      }
      {success && <TranCompleteModal onModalClose={handleCompleteModalClose} />}
    </Fragment>
  );
};

// Access to state
const mapStateToProps = state => {
  return {
    creditCard: state.paymentMethod.creditCard,
    appConfig: state.configuration.data,
    appConfigIsLoaded: state.configuration.isLoaded,
    isInitializing: state.paymentMethod.creditCard.getCards.isLoading ||  state.configuration.isLoading,
    isProcessing: state.paymentMethod.creditCard.journalCharge.isLoading || state.paymentMethod.creditCard.preAuthSalesOrder.isLoading || state.paymentMethod.creditCard.preAuthServiceOrder.isLoading,
    canSaveCreditCard: state.paymentMethod.creditCard.canSaveCreditCard
  }
};

// What actions are exposed on the component
const mapDispatchToProps = dispatch => ({
  deleteCard: (id) => dispatch(paymentMethodActions.deleteCard(id)),
  getAppConfig: (locationId) => dispatch(configurationActions.getAppConfiguration(locationId)),
  setSelectedCard: (card) => dispatch(paymentMethodActions.setSelectedCard(card)),
  getCardsForAccount: (accountNumber, locationId) => dispatch(paymentMethodActions.getCardsForAccount(accountNumber, locationId)),
  addCard: (cardData, nonce, cardholderName, saveCard, accountNumber, locationId, externalSystem) => dispatch(paymentMethodActions.addCard(cardData, nonce, cardholderName, saveCard, accountNumber, locationId, externalSystem)),
  addTestCard: (fakeCardId, cardholderName, saveCard, accountNumber, locationId, externalSystem) =>dispatch(paymentMethodActions.addTestCard(fakeCardId, cardholderName, saveCard, accountNumber, locationId, externalSystem)),
  journalCharge: (journalId, accountNumber, amount, currencyCode, locationId, cardId, externalSystem) => dispatch(paymentMethodActions.journalCharge(journalId, accountNumber, amount, currencyCode, locationId, cardId, externalSystem)),
  preAuthSalesOrder: (salesId, accountNumber, amount, currencyCode, locationId, cardId) => dispatch(paymentMethodActions.preAuthSalesOrder(salesId, accountNumber, amount, currencyCode, locationId, cardId)),
  preAuthServiceOrder: (serviceOrderId, accountNumber, amount, currencyCode, locationId, cardId) => dispatch(paymentMethodActions.preAuthServiceOrder(serviceOrderId, accountNumber, amount, currencyCode, locationId, cardId)),
  preAuthGenericOrder: (orderId, accountNumber, amount, currencyCode, locationId, cardId, transactionType, externalIdType, externalSystem) => dispatch(paymentMethodActions.preAuthGenericOrder(orderId, accountNumber, amount, currencyCode, locationId, cardId, transactionType, externalIdType, externalSystem)),
  canAccountSaveCreditCards: (accountNumber) => dispatch(paymentMethodActions.canAccountSaveCreditCards(accountNumber))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentMethod));