import { transactionTypeEnum } from '../shared/constants';

export const getExternalIdKVP = (queryParams) => {
  switch ((queryParams.type || '').toUpperCase()) {
    case transactionTypeEnum.SALES_ID:
      return { 
        'SALESORDER': queryParams.salesId,
        'PURCHASE_ORDER_NUMBER': queryParams.purchaseOrderNumber
      };
    case transactionTypeEnum.JOURNAL_ID:
      return { 
        'JOURNAL': queryParams.journalId
      };
    case transactionTypeEnum.SERVICE_PREAUTH:
    case transactionTypeEnum.SERVICE_ORDER_ID:
      return { 
        'SERVICEORDER': queryParams.serviceOrderId
      };
    default:
      return {};
  }
};

export const getExternalIdTypeName = (type) => {
  switch (type.toUpperCase()) {
    case transactionTypeEnum.SALES_ID.toUpperCase():
      return 'SALESORDER';
    case transactionTypeEnum.SERVICE_ORDER_ID.toUpperCase():
    case transactionTypeEnum.SERVICE_PREAUTH.toUpperCase():
      return 'SERVICEORDER';
    case transactionTypeEnum.JOURNAL_ID.toUpperCase():
      return 'JOURNAL';
    default:
      return type.toUpperCase();
  }
}

export const getTransactionsForExternalKVP = (queryParams) => {
  const { salesId, serviceOrderId } = queryParams;
  if (salesId) {
    return {
      externalType: 'SALESORDER',
      externalId: salesId,
    };
  } else if (serviceOrderId) {
    return {
      externalType: 'SERVICEORDER',
      externalId: serviceOrderId,
    };
  } else {
    return {};
  }
};

export const getExternalId = (queryParams) => {
  switch ((queryParams.type || '').toUpperCase()) {
    case transactionTypeEnum.SALES_ID:
      return ['SALESORDER', queryParams.salesId];
    case transactionTypeEnum.SERVICE_ORDER_ID:
    case transactionTypeEnum.SERVICE_PREAUTH:
      return ['SERVICEORDER', queryParams.serviceOrderId];
    case transactionTypeEnum.JOURNAL_ID:
      return ['JOURNAL', queryParams.journalId];
    default:
      return ['', ''];
  }
};

export const getTransactionTypeId = (queryParams) => {
  switch ((queryParams.type || '').toUpperCase()) {
    case transactionTypeEnum.SALES_ID:
      return 0;
    case transactionTypeEnum.SERVICE_ORDER_ID:
    case transactionTypeEnum.SERVICE_PREAUTH:
      return 1;
    case transactionTypeEnum.JOURNAL_ID:
      return 2;
    default:
      return -1;
  }
};

export const getExternalSystemId = (queryParams) => {
  switch ((queryParams.externalSystem || '').toUpperCase()) {
    case 'ORACLE': return 1;
    case 'HYBRIS': return 2;
    case 'AR': return 3;
    case 'AX':
    default: return 0;
  }
};

export const getExternalSystemDisplayName = (externalSystemId) => {
  switch (externalSystemId) {
    case 1: return 'Oracle';
    case 2: return 'Hybris';
    case 3: return 'AR';
    case 0:
    default: return 'AX';
  }
};


export const getLocationId = (queryParams) => {
  var currencyCode = queryParams.currencyCode?.toUpperCase();
  var locationId = queryParams.locationId;

  if (!locationId) {
    switch (currencyCode) {
      case 'CAD': return 'TORONTO';
      default: return 'OB'
    }
  }

  return locationId;
}